import React from "react";

import GiftcardImage1 from "../../assets/giftcard/images/trall-gavekort1.png"
import GiftcardImage2 from "../../assets/giftcard/images/trall-gavekort2.png"
import GiftcardImage3 from "../../assets/giftcard/images/trall-gavekort3.png"
import GiftcardImage4 from "../../assets/giftcard/images/trall-gavekort4.png"
import GiftcardImage5 from "../../assets/giftcard/images/trall-gavekort5.png"
import GiftcardImage6 from "../../assets/giftcard/images/trall-gavekort6.png"

import GiftcardPdf1 from "../../assets/giftcard/giftcards/trall-gavekort1.pdf"
import GiftcardPdf2 from "../../assets/giftcard/giftcards/trall-gavekort2.pdf"
import GiftcardPdf3 from "../../assets/giftcard/giftcards/trall-gavekort3.pdf"
import GiftcardPdf4 from "../../assets/giftcard/giftcards/trall-gavekort4.pdf"
import GiftcardPdf5 from "../../assets/giftcard/giftcards/trall-gavekort5.pdf"
import GiftcardPdf6 from "../../assets/giftcard/giftcards/trall-gavekort6.pdf"

import styles from "./styles.css";

function GiftcardImages () {
    return (
        <>
            <div className="alleBilderWrap">
                <div className="ettBildeMedKnapp">
                    <div>
                        <img className="bilde" src={GiftcardImage1} alt="Gavekort nummer en"/>
                    </div>
                    <div>
                        <a href={GiftcardPdf1}>
                            <button className="button">
                                <div>Se og last ned denne</div>
                            </button>
                        </a>
                    </div>
                </div>
                <div className="ettBildeMedKnapp">
                    <div>
                        <img className="bilde" src={GiftcardImage2} alt="Gavekort nummer to"/>
                    </div>
                    <div>
                        <a href={GiftcardPdf2}>
                            <button className="button">
                                <div>Se og last ned denne</div>
                            </button>
                        </a>
                    </div>
                </div>
                <div className="ettBildeMedKnapp">
                    <div>
                        <img className="bilde" src={GiftcardImage3} alt="Gavekort nummer tre"/>
                    </div>
                    <div>
                        <a href={GiftcardPdf3}>
                            <button className="button">
                                <div>Se og last ned denne</div>
                            </button>
                        </a>
                    </div>
                </div>
                <div className="ettBildeMedKnapp">
                    <div>
                        <img className="bilde" src={GiftcardImage4} alt="Gavekort nummer fire"/>
                    </div>
                    <div>
                        <a href={GiftcardPdf4}>
                            <button className="button">
                                <div>Se og last ned denne</div>
                            </button>
                        </a>
                    </div>
                </div>
                <div className="ettBildeMedKnapp">
                    <div>
                        <img className="bilde" src={GiftcardImage5} alt="Gavekort nummer fem"/>
                    </div>
                    <div>
                        <a href={GiftcardPdf5}>
                            <button className="button">
                                <div>Se og last ned denne</div>
                            </button>
                        </a>
                    </div>
                </div>
                <div className="ettBildeMedKnapp">
                    <div>
                        <img className="bilde" src={GiftcardImage6} alt="Gavekort nummer seks"/>
                    </div>
                    <div>
                        <a href={GiftcardPdf6}>
                            <button className="button">
                                <div>Se og last ned denne</div>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GiftcardImages;
