import React from "react";
import { graphql } from "gatsby";

import GiftcardPage from "@components/GiftcardPage";

const Giftcard = ({ data }) => {
    const { pageData } = data;
    return <GiftcardPage {...pageData} />;
};

export const query = graphql`
    query GiftcardPageQuery {
        pageData: sanityGiftcardPage {
            title
            intro {
                ...SanityTextBoxFragment
            }
            backgroundImage {
                ...SanityBackgroundImageFragment
            }
            giftcardBlock {
                content {
                    ...SanityTextBoxFragment
                }
                backgroundColor {
                    value
                }
            }
        }
    }
`;

export default Giftcard;