import React from "react";

import TextBox from "@components/TextBox";
import Hero from "@components/Hero";
import GiftcardBlock from "@components/GiftcardBlock";

import * as styles from "./styles.module.css";

const GiftcardPage = ({
    title,
    backgroundImage,
    intro,
    giftcardBlock
}) => {
    return (
        <>
            <Hero
                {...{
                    title,
                    backgroundImage,
                    className: styles.hero,
                }}
            >
                <TextBox {...intro} className={styles.box} />
            </Hero>

            <GiftcardBlock {...giftcardBlock} />
            
        </>
    )
}; 

export default GiftcardPage;