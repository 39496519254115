import React from "react";
import { convertBgImageToSources } from "@utils/templateHelpers";

import TextBox from "@components/TextBox";
import Section from "@components/Section";
import GiftcardDownload from "@components/GiftcardDownload"

import * as styles from "./styles.module.css";

const GiftcardBlock = ({ content, backgroundColor, images, buttons }) => {

  return (
    <Section as="section"
    className={styles.container}
    backgroundColor={backgroundColor?.value}
    >
      <TextBox {...content} whiteBox={false} className={styles.box} />

      <GiftcardDownload images={images} buttons={buttons} />
    </Section>
  ); 
}; 

export default GiftcardBlock;